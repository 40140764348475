<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <select
      v-model="value"
      :class="`dropdown-multiselect${classes}`"
      :disabled="disabled"
      multiple="multiple"
    >
      <option
        v-for="(option, i) in options"
        :key="`multiselect_option_${i}`"
        :value="i"
        :data-color="option.color"
        :style="`background-color: ${option.color};`"
      >
        {{ option.text }}
      </option>
    </select>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeMultiselectOpen',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    options() {
      const optionsOriginal = this.cell.settings.dropdown_builder || []
      const optionsSorting = parseInt(this.cell.settings.dropdown_sorting || 0) || 0
      const options = []
      if (!Array.isArray(optionsOriginal)) {
        return options
      }
      for (let i = 0, option; (option = optionsOriginal[i++]);) {
        if (typeof option === 'string') {
          option = {
            value: option,
            color: ''
          }
        }
        option.id = i
        option.text = this.optionLabel(option)
        options.push(option)
      }
      if (optionsSorting === 1) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return 1;
          }
          if (a.text < b.text) {
            return -1;
          }
          return 0;
        })
      }
      if (optionsSorting === 2) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return -1;
          }
          if (a.text < b.text) {
            return 1;
          }
          return 0;
        })
      }
      return options
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = builder.getCellValue(index, this.cell, this.clone)
        try {
          if (typeof value === 'string') {
            value = JSON.parse(value)
          }
        } catch (ex) {
          value = []
        }
        value = builder.getSelectPropertyToArray(value, 'id')
        return value
      },
      set(selected) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const value = []
        if (!Array.isArray(selected)) {
          selected = [selected]
        }
        for (let i = 0, ilength = selected.length; i < ilength; i++) {
          const id = selected[i]
          value[i] = {
            id: id,
            label: this.optionLabel(this.options[id])
          }
        }
        const previousValue = this.value
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  methods: {
    optionLabel(option) {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(option, 'value') || ''
    }
  }
}
</script>
